import $i18n from 'panda-i18n';
import React, { useContext, useState } from 'react';
import { CnDialogFullpage } from '@/components/cn-dialog-fullpage';
import { CnButtonGroup } from '@/components/cn-button-group';
import { useItemButtons } from '../../hooks/use-item-buttons';
import { ItemCard } from '../item-card';
import { ItemForm } from '../item-form';
import { CardItemsStateCtx } from '../../context/card-items';
/** 列表项 */
export var Item = function (props) {
    var rowIndex = props.rowIndex, data = props.data;
    var buttons = useItemButtons(props);
    var _a = useState(false), visible = _a[0], setVisible = _a[1];
    var disableDetail = useContext(CardItemsStateCtx).disableDetail;
    return (React.createElement(React.Fragment, null,
        React.createElement(ItemCard, { rowIndex: rowIndex, data: data, footAction: { buttons: buttons }, onClick: disableDetail
                ? undefined
                : function (event) {
                    setVisible(true);
                    event.preventDefault();
                    event.stopPropagation();
                } }),
        disableDetail ? null : (React.createElement(CnDialogFullpage, { title: $i18n.get({
                id: '31255239428542464.CNTM',
                dm: '详情',
                ns: 'CnCardItems',
            }), visible: visible, onClose: function () { return setVisible(false); }, footer: buttons.length ? (React.createElement(CnButtonGroup, { isReverse: true, size: "large", fullWidth: true }, buttons)) : (false) },
            React.createElement(ItemForm, { rowIndex: rowIndex, data: data })))));
};
