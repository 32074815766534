import { __assign, __rest } from "tslib";
import { useI18n } from 'panda-i18n';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { CnCard } from '@/components/cn-card';
import { CnIcon } from '@/components/cn-icon';
import { CnButton } from '@/components/cn-button';
import { CnDrawer } from '@/components/cn-drawer';
import { CnPage } from '@/components/cn-page';
import { getComponentName } from '@/utils/get-component-name';
import { CnButtonGroup } from '../cn-button-group';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
import { CnEllipsis } from '@/components/cn-ellipsis';
var classNamePrefix = 'cn-ui-m-dialog-fullpage';
export var CnDialogFullpage = function (props) {
    var footer = props.footer, onClose = props.onClose, onCancel = props.onCancel, className = props.className, children = props.children, onOk = props.onOk, _a = props.okProps, okProps = _a === void 0 ? {} : _a, _b = props.cancelProps, cancelProps = _b === void 0 ? {} : _b, title = props.title, _c = props.visible, visibleProps = _c === void 0 ? false : _c, noCard = props.noCard, others = __rest(props, ["footer", "onClose", "onCancel", "className", "children", "onOk", "okProps", "cancelProps", "title", "visible", "noCard"]);
    var hasCard = function () {
        var _a;
        if (noCard) {
            return true;
        }
        var types = [];
        if (Array.isArray(children)) {
            types = children.map(function (item) { var _a; return (_a = item === null || item === void 0 ? void 0 : item.type) === null || _a === void 0 ? void 0 : _a.displayName; });
        }
        else {
            types = [((_a = children === null || children === void 0 ? void 0 : children.type) === null || _a === void 0 ? void 0 : _a.displayName) || ''];
        }
        return types.includes('CnCard');
    };
    var $i18n = useI18n();
    var _d = useState(visibleProps), visible = _d[0], setVisible = _d[1];
    useEffect(function () {
        setVisible(function (prev) {
            if (prev === visibleProps)
                return prev;
            return visibleProps;
        });
    }, [visibleProps]);
    var renderFooter = function () {
        if (footer === false)
            return null;
        var isValidFooter = React.isValidElement(footer);
        var displayName = getComponentName(footer);
        if (isValidFooter && displayName && ['CnPageFooter', 'CnButtonGroup'].includes(displayName)) {
            return footer;
        }
        var okChildren = okProps.children, _a = okProps.visible, okVisible = _a === void 0 ? true : _a, restOkProps = __rest(okProps, ["children", "visible"]);
        var cancelChildren = cancelProps.children, _b = cancelProps.visible, cancelVisible = _b === void 0 ? true : _b, restCancelProps = __rest(cancelProps, ["children", "visible"]);
        if (!okVisible && !cancelVisible && !footer)
            return null;
        return (React.createElement(CnButtonGroup, { fullWidth: true, size: "large" },
            typeof footer === 'boolean' ? null : footer,
            cancelVisible ? (React.createElement(CnButton, __assign({ onClick: onCancel }, restCancelProps), cancelChildren ||
                $i18n.get({
                    id: 'Cancel',
                    dm: '取消',
                    ns: 'CnDialogFullpage',
                }))) : null,
            okVisible ? (React.createElement(CnButton, __assign({ onClick: onOk }, restOkProps), okChildren ||
                $i18n.get({
                    id: 'Determine',
                    dm: '确定',
                    ns: 'CnDialogFullpage',
                }))) : null));
    };
    return withNativeProps(props, React.createElement(CnDrawer, __assign({ visible: visible, onClose: onClose, onCancel: onCancel, className: classNames(CN_UI_HASH_CLASS_NAME, classNamePrefix) }, others, { placement: "right", closeMode: [], noCard: true }),
        React.createElement(CnPage, { header: React.createElement("div", { className: "".concat(classNamePrefix, "-header") },
                React.createElement("div", { className: "".concat(classNamePrefix, "-header-close"), onClick: function (e) {
                        e.stopPropagation();
                        setVisible(false);
                        // 只需要执行一个，一码多端兼容
                        if (onClose) {
                            onClose();
                        }
                        else if (onCancel) {
                            onCancel();
                        }
                    } },
                    React.createElement(CnIcon, { size: "large", type: "close" })),
                React.createElement("div", { className: "".concat(classNamePrefix, "-header-title") },
                    React.createElement(CnEllipsis, null, title))), footer: renderFooter(), className: classNames("".concat(classNamePrefix, "-body")), "data-hideHeader": true }, hasCard() ? children : React.createElement(CnCard, null, children))));
};
CnDialogFullpage.displayName = 'CnDialogFullpage';
