import './cn-form-item.scss';
import * as React from 'react';
import { CnPopover } from '@/components/cn-popover';
import { CnIcon } from '@/components/cn-icon';
import classNames from 'classnames';
var formatErrors = function (errors) {
    if (!Array.isArray(errors) || !errors.length)
        return [];
    return errors.filter(function (item) { return typeof item !== 'boolean' && Boolean(item); });
};
export var CnFormItem = function (props) {
    var required = props.required, asterisk = props.asterisk, title = props.title, tip = props.tip, extra = props.extra, readOnly = props.readOnly, labelWidth = props.labelWidth, labelAlign = props.labelAlign, labelTextAlign = props.labelTextAlign, children = props.children, wrapperAlign = props.wrapperAlign, hasArrow = props.hasArrow, className = props.className, style = props.style, id = props.id, disabled = props.disabled, triggerName = props.triggerName, triggerRef = props.triggerRef;
    var errors = formatErrors(props.errors);
    var renderLabel = function () {
        var _a;
        if (!title && !tip) {
            return null;
        }
        var requiredStatus = false;
        if (required || asterisk) {
            requiredStatus = true;
        }
        if (readOnly) {
            requiredStatus = false;
        }
        return (React.createElement("div", { className: classNames('cn-ui-m-form-item-label', (_a = {
                    'cn-ui-m-form-item-label--left': labelAlign === 'left'
                },
                _a["cn-ui-m-form-item-label--text-".concat(labelTextAlign)] = !!labelTextAlign,
                _a)), style: labelAlign === 'left'
                ? {
                    width: labelWidth || '112px',
                    flexShrink: 0,
                }
                : {} },
            React.createElement("div", { className: "cn-ui-m-form-item-label-content" },
                React.createElement("div", { className: "cn-ui-m-form-item-label-title" }, title),
                tip ? (React.createElement(CnPopover, { content: tip, trigger: "click", placement: "top" },
                    React.createElement(CnIcon, { className: "cn-ui-m-form-item-label-tip", type: "help", size: "xs" }))) : null,
                requiredStatus && React.createElement("div", { className: "cn-ui-m-form-item-required" }, '*'))));
    };
    var renderWrapper = function () {
        var _a;
        return (React.createElement("div", { key: "control", className: classNames('cn-ui-m-form-item-control', (_a = {},
                _a["cn-ui-m-form-item-control--".concat(wrapperAlign)] = !!wrapperAlign,
                _a)) }, React.isValidElement(children)
            ? React.cloneElement(children, {
                insideForm: true,
            })
            : children));
    };
    var renderIcon = function () {
        // 如放置组件为 CnSelect 等需要显示 arrow
        if (hasArrow && triggerName && ['CnSelect'].indexOf(triggerName) > 0) {
            return (React.createElement("div", { className: "cn-ui-m-form-item-header--ft" },
                React.createElement("div", { className: classNames('cn-ui-m-form-item-icon', {
                        'cn-ui-m-form-item-icon--error': Array.isArray(errors) && errors.length > 0,
                    }) },
                    React.createElement(CnIcon, { type: "icon-arrow-right", size: "large" }))));
        }
        return null;
    };
    var Errors = function () {
        if (!Array.isArray(errors) || !errors.length)
            return null;
        var _errors = errors.filter(function (item) { return typeof item !== 'boolean'; });
        if (!_errors.length)
            return null;
        return (React.createElement("div", { className: "cn-ui-m-form-item-error" }, _errors.map(function (errorItem) { return "".concat(errorItem).concat(errors.length > 1 ? ';' : ''); })));
    };
    var onItemClick = function () {
        var _a, _b;
        if (triggerName && triggerRef) {
            if (triggerName === 'CnInput') {
                (_a = triggerRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            }
            if (triggerName === 'CnSelect') {
                (_b = triggerRef.current) === null || _b === void 0 ? void 0 : _b.show();
            }
        }
    };
    return (React.createElement("div", { style: style, className: classNames(CN_UI_HASH_CLASS_NAME, className, {
            'cn-ui-m-form-item': true,
            'cn-ui-m-form-item--left': labelAlign === 'left',
            'cn-ui-m-form-item-disabled': disabled,
            'cn-ui-m-form-item-no-label': !title,
        }), id: id, role: "cn-form-item-m", onClick: onItemClick },
        React.createElement("div", { className: classNames('cn-ui-m-form-item-header', {
                'has-error': Array.isArray(errors) && errors.length > 0,
            }) },
            React.createElement("div", { className: "cn-ui-m-form-item-header--hd" },
                renderLabel(),
                renderWrapper()),
            renderIcon(),
            props.rightExtra ? (React.createElement("div", { className: "cn-ui-m-form-item-header--right-extra" }, props.rightExtra)) : null),
        React.createElement(Errors, null),
        extra ? React.createElement("div", { className: "cn-ui-m-form-item__extra" }, extra) : null));
};
CnFormItem.displayName = 'CnFormItem';
