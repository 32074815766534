import { __assign } from "tslib";
import React, { useContext } from 'react';
import classNames from 'classnames';
import { CnCheckbox } from '@/components/cn-checkbox';
import { CnRadio } from '@/components/cn-radio';
import { CnListProContext } from '../hooks';
import omit from 'lodash/omit';
import assign from 'lodash/assign';
import './cn-list-pro-item.scss';
/**
 * 根据props获取rowData
 * @param props 属性
 * @returns rowData
 */
var getRowData = function (props) {
    if (props.rowData) {
        return props.rowData;
    }
    var possibleProps = omit(props, [
        'children',
        'itemKey',
        'className',
        'style',
    ]);
    return assign({
        key: props.itemKey,
    }, possibleProps);
};
export var CnListProItem = function (props) {
    var className = props.className, style = props.style, title = props.title, description = props.description, avatar = props.avatar, actions = props.actions, extra = props.extra, itemKey = props.itemKey, onClick = props.onClick, disabled = props.disabled, bottomArea = props.bottomArea;
    var _a = useContext(CnListProContext) || {}, rowSelection = _a.rowSelection, rowSelectionStore = _a.rowSelectionStore, contextItemKey = _a.itemKey, setRowSelectionStore = _a.setRowSelectionStore, imagePosition = _a.imagePosition, shape = _a.shape;
    // 卡片每一项的className
    var classes = classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-m-list-pro-item', "cn-ui-m-list-pro-item--".concat(shape), className, {
        'cn-ui-m-list-pro-item--disabled': disabled,
        'cn-ui-m-list-pro-item--image--top': imagePosition === 'top',
    });
    // 卡片内容的className
    var contentClasses = classNames('cn-ui-m-list-pro-item__content', {
        'cn-ui-m-list-pro-item__content--image--top': imagePosition === 'top',
    });
    // 头像的className
    var avatarClasses = classNames('cn-ui-m-list-pro-item__content--avatar', {
        'cn-ui-m-list-pro-item__content--avatar--top': imagePosition === 'top',
    });
    // 额外区域的className
    var extraClasses = classNames('cn-ui-m-list-pro-item__extra');
    // 子元素的className
    var childrenClasses = classNames('cn-ui-m-list-pro-item__children');
    // 底部区域的className
    var bottomAreaClasses = classNames('cn-ui-m-list-pro-item__bottom-area');
    var primaryItemKey = itemKey || props[contextItemKey];
    // 选中的卡片的keys
    var selectedRowKeys = (rowSelection === null || rowSelection === void 0 ? void 0 : rowSelection.selectedRowKeys) || (rowSelectionStore === null || rowSelectionStore === void 0 ? void 0 : rowSelectionStore.selectedRowKeys);
    // 选中的卡片的rows
    var selectedRows = (rowSelection === null || rowSelection === void 0 ? void 0 : rowSelection.selectedRows) || (rowSelectionStore === null || rowSelectionStore === void 0 ? void 0 : rowSelectionStore.selectedRows);
    var currentRowData = getRowData(props);
    // 当前卡片是否选中
    var isSelected = selectedRowKeys.includes(primaryItemKey);
    var checkComponentProps = {
        onChange: function (checked) {
            var _a, _b;
            if ((rowSelection === null || rowSelection === void 0 ? void 0 : rowSelection.type) === 'multiple') {
                if (checked) {
                    if (!selectedRowKeys.includes(primaryItemKey)) {
                        selectedRowKeys.push(primaryItemKey);
                        selectedRows.push(currentRowData);
                    }
                }
                else if (selectedRowKeys.includes(primaryItemKey)) {
                    selectedRowKeys.splice(selectedRowKeys.indexOf(primaryItemKey), 1);
                    selectedRows.splice(selectedRows.indexOf(currentRowData), 1);
                }
                if (!(rowSelection === null || rowSelection === void 0 ? void 0 : rowSelection.selectedRowKeys)) {
                    setRowSelectionStore({
                        selectedRowKeys: selectedRowKeys,
                        selectedRows: selectedRows,
                    });
                }
                (_a = rowSelection === null || rowSelection === void 0 ? void 0 : rowSelection.onChange) === null || _a === void 0 ? void 0 : _a.call(rowSelection, currentRowData, selectedRowKeys, selectedRows);
            }
            else if (!isSelected) {
                selectedRowKeys = [];
                selectedRows = [];
                if (checked) {
                    selectedRowKeys.push(primaryItemKey);
                    selectedRows.push(currentRowData);
                }
                if (!(rowSelection === null || rowSelection === void 0 ? void 0 : rowSelection.selectedRowKeys)) {
                    setRowSelectionStore({
                        selectedRowKeys: selectedRowKeys,
                        selectedRows: selectedRows,
                    });
                }
                (_b = rowSelection === null || rowSelection === void 0 ? void 0 : rowSelection.onChange) === null || _b === void 0 ? void 0 : _b.call(rowSelection, currentRowData, selectedRowKeys, selectedRows);
            }
        },
        onClick: function (e) {
            e.stopPropagation();
        },
        checked: isSelected,
    };
    // 点击事件
    var handleClick = function (e) {
        if (disabled) {
            return;
        }
        onClick && onClick(e, currentRowData);
    };
    // 渲染选中
    var renderCheck = function () { return (rowSelection ? (React.createElement("div", { className: "cn-ui-m-list-pro-item__content--check" }, (rowSelection === null || rowSelection === void 0 ? void 0 : rowSelection.type) === 'multiple' ? (React.createElement(CnCheckbox, __assign({}, checkComponentProps))) : (React.createElement(CnRadio, __assign({}, checkComponentProps))))) : null); };
    // 渲染头像
    var renderAvatar = function () { return (avatar ? (React.createElement("div", { className: avatarClasses }, avatar)) : null); };
    // 渲染详情
    var renderDetail = function () { return (title || description ? (React.createElement("div", { className: "cn-ui-m-list-pro-item__content--details" },
        title ? (React.createElement("div", { className: "cn-ui-m-list-pro-item__content--title" }, title)) : null,
        description ? (React.createElement("div", { className: "cn-ui-m-list-pro-item__content--description" }, description)) : null)) : null); };
    // 渲染操作
    var renderAction = function () { return (actions ? (React.createElement("div", { className: "cn-ui-m-list-pro-item__content--actions" }, actions)) : null); };
    // 渲染额外区域
    var renderExtra = function () { return (extra ? React.createElement("div", { className: extraClasses }, extra) : null); };
    // 渲染子元素
    var renderChildren = function () { return React.createElement("div", { className: childrenClasses }, props.children); };
    // 渲染内容
    var renderContent = function () {
        if (imagePosition !== 'top') {
            return avatar || title || description || actions ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: contentClasses },
                    renderCheck(),
                    renderAvatar(),
                    renderDetail(),
                    renderAction()),
                renderExtra(),
                renderChildren())) : null;
        }
        return avatar || title || description || actions ?
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: "cn-ui-m-list-pro-item__middile-wrapper" },
                    renderAvatar(),
                    React.createElement("div", { className: contentClasses },
                        renderCheck(),
                        renderDetail(),
                        renderAction()),
                    renderExtra(),
                    renderChildren()))
            : null;
    };
    return (React.createElement("div", { className: classes, style: style, onClick: handleClick },
        renderContent(),
        bottomArea && React.createElement("div", { className: bottomAreaClasses }, bottomArea)));
};
CnListProItem.displayName = 'CnListProItem';
