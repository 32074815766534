import { __assign } from "tslib";
import './locales/init';
import './styles/global-style.scss';
import { events, useControlled } from '@cainiaofe/cn-ui-common';
import * as plugin from '@cainiaofe/cn-ui-common/es/utils/plugin';
// eslint-disable-next-line spaced-comment
/*-- cone add component export 初始化 start，勿删 --*/
export * from './components/cn-action-sheet';
export * from './components/cn-button';
export * from './components/cn-modal';
export * from './components/cn-dialog';
export * from './components/cn-switch';
export * from './components/cn-message';
export * from './components/cn-notice';
export * from './components/cn-card';
export * from './components/cn-grid-layout';
export * from './components/cn-skeleton';
export * from './components/cn-radio';
export * from './components/cn-result';
export * from './components/cn-checkbox';
export * from './components/cn-select';
export * from './components/cn-input';
export * from './components/cn-input-textarea';
export * from './components/cn-image-viewer';
export * from './components/cn-loading';
export * from './components/cn-tab';
export * from './components/cn-water-mark';
export * from './components/cn-scroll-view';
export * from './components/cn-safe-area';
export * from './components/cn-box';
export * from './components/cn-tag';
export * from './components/cn-filter';
export * from './components/cn-icon';
export * from './components/cn-ellipsis';
export * from './components/cn-entry-points';
export * from './components/cn-page';
export * from './components/cn-page-footer';
export * from './components/cn-dialog-fullpage';
export * from './components/cn-badge';
export * from './components/cn-number-picker';
export * from './components/cn-step';
export * from './components/cn-drawer';
export * from './components/cn-demo';
export * from './components/cn-form-item';
export * from './components/cn-oss-upload';
export * from './components/cn-oss-image-upload';
export * from './components/cn-dynamic-form';
export * from './components/cn-list';
export * from './components/cn-search';
export * from './components/cn-rating';
export * from './components/cn-card-items';
export * from './components/cn-popover';
export * from './components/cn-picker';
export * from './components/cn-picker-view';
export * from './components/cn-date-picker-pro';
export * from './components/cn-calendar';
export * from './components/cn-time-picker-pro';
export * from './components/cn-department-select';
export * from './components/cn-employee-select';
export * from './components/cn-async-select';
export * from './components/cn-cascader-select';
export * from './components/cn-detail-info';
export * from './components/cn-selector';
export * from './components/cn-slider';
export * from './components/cn-affix';
export * from './components/cn-button-group';
export * from './components/cn-currency-select';
export * from './components/cn-read-only';
export * from './components/cn-pull-to-refresh';
export * from './components/cn-typography';
export * from './components/cn-progress';
export * from './components/cn-nav-bar';
export * from './components/cn-i18n-select';
export * from './components/cn-agreement';
export * from './components/cn-bottom-nav-bar';
export * from './components/cn-mini-table';
export * from './components/cn-pagination';
export * from './components/cn-format';
export * from './components/cn-list-pro';
export * from './form';
export * from './utils/dayjs';
// eslint-disable-next-line spaced-comment
/*-- cone add component export 初始化 end，勿删 --*/
var cnUtils = __assign(__assign({ events: events }, plugin), { useControlled: useControlled });
export { cnUtils };
try {
    // @ts-ignore 忽略版本ts校验
    window.CN_UI_M_VERSION = CN_UI_M_VERSION;
    // @ts-ignore 忽略版本ts校验
    console.log('cn-ui-m 的版本号为：', window.CN_UI_M_VERSION);
}
catch (e) {
    console.error(e);
}
